@use 'palette';
@use '@angular/material' as mat;

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

@font-face {
  font-family: "Anton";
  src:url("./assets/fonts/Anton-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "WorkSans";
  src:url("./assets/fonts/WorkSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html, body {
  font-family: WorkSans;
  background-color: palette.$bg-light-primary;
}

.landing-page-wrapper {
  margin-top: 85px;
}

.box {
  background-color: palette.$bg-light-secondary;
  padding: 1rem 1rem;
  margin: 1.5rem 0;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 1rem;
  box-shadow: 0 4px 25px #0e0e0e29;
}

a {
  color: palette.$default-text;
  text-decoration: none;
}

.rs-title-lg {
  font-family: Anton;
  font-size: 3.5rem;
}

.rs-title-m {
  font-family: Anton;
  font-size: 2;
}

.rs-subtitle-m {
  font-size: 1.5rem;
}

// Color for the active links.
.active-link,
.active-link:active,
.active-link:hover,
.active-link:visited {
  color: palette.$active !important;
  text-decoration: none;
}

.mat-drawer-container {
  background-color: unset !important;
}

.mat-drawer {
  background-color: palette.$bg-dark-primary !important;
}

ul, li {
  list-style-type: none;
  padding: 0;
}

/**
  * The followings are required to make the vertical scroll work
  * in the gallery component.
  */
mat-sidenav-container.mat-sidenav-container {
  overflow: unset !important;
}

mat-sidenav-content.mat-sidenav-content {
  overflow: unset !important;
}

mat-sidenav {
  width: 250px;
}

.mat-icon-2x {
  transform: scale(2);
}

.mat-icon-3x {
  transform: scale(3);
}

// Required by the Angular WYSIWYG Editor.
// https://github.com/kolkov/angular-editor/issues/205
angular-editor-toolbar button i {
  font-family: FontAwesome !important;
  color: #474d6d !important;
}

.angular-editor-toolbar {
  background-color: unset !important;
  border: none !important;
  padding: 0 !important;
}

.angular-editor-textarea {
  color: rgb(183 183 174) !important;
  background-color: palette.$bg-light-primary !important;
  border: none !important;

}

.angular-editor-textarea:focus {
  border-bottom: 2px solid #32fa68 !important;
  outline: none !important;
}

.angular-editor-button.active {
  background: #32fa68 !important;
}

// For generating color palettes:
// http://mcg.mbitson.com
// https://materialtheme.arcsine.dev



// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/.
$roadspace-app-primary: mat.define-palette(palette.$roadspace-primary, 900, 800, 900);
$roadspace-app-accent: mat.define-palette(palette.$roadspace-accent, 900, 800, 900);
$roadspace-app-warn: mat.define-palette(palette.$roadspace-warn, 800, 700, 900);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$roadspace-app-theme: mat.define-light-theme((
  color: (
    primary: $roadspace-app-primary,
    accent: $roadspace-app-accent,
    warn: $roadspace-app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($roadspace-app-theme);
