// Naming it _palette.scss so it doesn't get compiled into a separate css file.
// This is a partial file that is imported into other scss files.

$light-primary-text: black;
$dark-primary-text: rgba(black, 0.87);

$roadspace-primary: (
  50 : #e8fef2,
  100 : #c5fce0,
  200 : #9ffacb,
  300 : #78f7b6,
  400 : #5bf6a6,
  500 : #3ef496,
  600 : #38f38e,
  700 : #30f183,
  800 : #28ef79,
  900 : #1bec68,
  A100 : #ffffff,
  A200 : #ecfff3,
  A400 : #b9ffd1,
  A700 : #a0ffc0,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$roadspace-accent: (
  50 : #efefef,
  100 : #d7d7d7,
  200 : #bcbcbc,
  300 : #a1a1a1,
  400 : #8d8d8d,
  500 : #797979,
  600 : #717171,
  700 : #666666,
  800 : #5c5c5c,
  900 : #494949,
  A100 : #f7b6b6,
  A200 : #f28888,
  A400 : #ff4747,
  A700 : #ff2e2e,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$roadspace-warn: (
  50 : #ffe0e0,
  100 : #ffb3b3,
  200 : #ff8080,
  300 : #ff4d4d,
  400 : #ff2626,
  500 : #ff0000,
  600 : #ff0000,
  700 : #ff0000,
  800 : #ff0000,
  900 : #ff0000,
  A100 : #ffffff,
  A200 : #fff2f2,
  A400 : #ffbfbf,
  A700 : #ffa6a6,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$bg-light-primary: #eaeaea;
$bg-light-secondary: #ffffff;

$bg-dark-primary: #292e3c;
// $sidenav-bg-dark: #1a1d20;
$default-text: rgba(230, 222, 229, 0.75);
$active: #3EF496;
$bg-emphasis: map-get($roadspace-primary, 900);
